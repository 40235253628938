#app{
    display:grid;
    grid-template-areas: 
    "sidebar header"
    "sidebar main";
    
    min-height: 100vh;
    
    grid-template-rows: max-content 1fr;
    grid-template-columns: max-content 1fr;

    background-color: #fafbfe;
}

#header{
    grid-area: header;
    display:grid;
    grid-template-columns: 1fr max-content;
    grid-auto-flow: column;
    padding: 10px;
}

#main{
    grid-area:main;
}

#sidebar-container{
    grid-area: sidebar;
}

.tabulator-cell{
    height: 42px!important;
}
.modal-wrap{
    z-index: 100000;
}
.historyfilter{
  padding:2.5rem 1.5rem 3rem;
  background-color: white!important;
    border-radius:10px;
}

.ggr-content{
    display:grid;
    grid-auto-flow: column;
    grid-template-columns: max-content max-content 1fr;
    grid-column-gap: 5rem;
    padding:1rem;
}
.ggr-content>form{
    display: grid;
    grid-template-areas: "one two";
    grid-column-gap: 1rem;
    
}
.ggr-items{
    display:grid;
    grid-auto-flow: column;
    grid-column-gap: 2rem;
    align-content: center;
}
.ggr-item {
    min-width:195px !important;
}
.ggr-item span{
    font-size: 1.5rem;
    display: block;
}
.ggr-item span:nth-child(1){
    font-weight: 600;
    font-size: 1rem;
}

.toolbar-content{
    display:grid;
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr max-content;
}
.tabs .is-active{
    background-color: #313a46;
    color:white;
}#app{
    display:grid;
    grid-template-areas: 
    "sidebar header"
    "sidebar main";
    
    min-height: 100vh;
    
    grid-template-rows: max-content 1fr;
    grid-template-columns: max-content 1fr;

    background-color: #fafbfe;
}

#header{
    grid-area: header;
    display:grid;
    grid-template-columns: 1fr max-content;
    grid-auto-flow: column;
    padding: 10px;
}

#main{
    grid-area:main;
}

#sidebar-container{
    grid-area: sidebar;
}

.tabulator-cell{
    height: 42px!important;
}
.modal-wrap{
    z-index: 100000;
}
.historyfilter{
  padding:2.5rem 1.5rem 3rem;
  background-color: white!important;
    border-radius:10px;
}

.ggr-content{
    display:grid;
    grid-auto-flow: column;
    grid-template-columns: max-content max-content 1fr;
    grid-column-gap: 5rem;
    padding:1rem;
}
.ggr-content>form{
    display: grid;
    grid-template-areas: "one two";
    grid-column-gap: 1rem;
    
}
.ggr-items{
    display:grid;
    grid-auto-flow: column;
    grid-column-gap: 2rem;
    align-content: center;
}
.ggr-item {
    min-width:195px !important;
}
.ggr-item span{
    font-size: 1.5rem;
    display: block;
}
.ggr-item span:nth-child(1){
    font-weight: 600;
    font-size: 1rem;
}

.toolbar-content{
    display:grid;
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr max-content;
}
.tabs .is-active{
    background-color: #313a46;
    color:white;
}

.modal-wrap{
    background-color: #efefef;
    padding:4rem 2.5rem 2rem;
    border-radius: 10px;
    max-height: 90vh;
    overflow: auto;
}

.tabulator-cell,
.tabulator-responsive-collapse td,
.tabulator-col-title{
  font-size: 11px;
}

body:not(.has-sidebar) .tabulator-cell,
.tabulator-responsive-collapse td,
.tabulator-col-title{
  font-size: 15px!important;
}

.modal-wrap.edit-user{
    height: 400px!important;
    display: block;
    max-height: 600px!important;
}

.vscomp-ele{
    min-width: 100%;
}
.vscomp-value{
    width: 100%!important;
    height: max-content;
}