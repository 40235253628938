#app {
  min-height: 100vh;
  background-color: #fafbfe;
  grid-template: "sidebar header" max-content
                 "sidebar main" 1fr
                 / max-content 1fr;
  display: grid;
}

#header {
  grid-area: header;
  grid-template-columns: 1fr max-content;
  grid-auto-flow: column;
  padding: 10px;
  display: grid;
}

#main {
  grid-area: main;
}

#sidebar-container {
  grid-area: sidebar;
}

.tabulator-cell {
  height: 42px !important;
}

.modal-wrap {
  z-index: 100000;
}

.historyfilter {
  border-radius: 10px;
  padding: 2.5rem 1.5rem 3rem;
  background-color: #fff !important;
}

.ggr-content {
  grid-column-gap: 5rem;
  grid-template-columns: max-content max-content 1fr;
  grid-auto-flow: column;
  padding: 1rem;
  display: grid;
}

.ggr-content > form {
  grid-column-gap: 1rem;
  grid-template-areas: "one two";
  display: grid;
}

.ggr-items {
  grid-column-gap: 2rem;
  grid-auto-flow: column;
  align-content: center;
  display: grid;
}

.ggr-item {
  min-width: 195px !important;
}

.ggr-item span {
  font-size: 1.5rem;
  display: block;
}

.ggr-item span:nth-child(1) {
  font-size: 1rem;
  font-weight: 600;
}

.toolbar-content {
  grid-template-columns: max-content 1fr max-content;
  grid-auto-flow: column;
  display: grid;
}

.tabs .is-active {
  color: #fff;
  background-color: #313a46;
}

#app {
  min-height: 100vh;
  background-color: #fafbfe;
  grid-template: "sidebar header" max-content
                 "sidebar main" 1fr
                 / max-content 1fr;
  display: grid;
}

#header {
  grid-area: header;
  grid-template-columns: 1fr max-content;
  grid-auto-flow: column;
  padding: 10px;
  display: grid;
}

#main {
  grid-area: main;
}

#sidebar-container {
  grid-area: sidebar;
}

.tabulator-cell {
  height: 42px !important;
}

.modal-wrap {
  z-index: 100000;
}

.historyfilter {
  border-radius: 10px;
  padding: 2.5rem 1.5rem 3rem;
  background-color: #fff !important;
}

.ggr-content {
  grid-column-gap: 5rem;
  grid-template-columns: max-content max-content 1fr;
  grid-auto-flow: column;
  padding: 1rem;
  display: grid;
}

.ggr-content > form {
  grid-column-gap: 1rem;
  grid-template-areas: "one two";
  display: grid;
}

.ggr-items {
  grid-column-gap: 2rem;
  grid-auto-flow: column;
  align-content: center;
  display: grid;
}

.ggr-item {
  min-width: 195px !important;
}

.ggr-item span {
  font-size: 1.5rem;
  display: block;
}

.ggr-item span:nth-child(1) {
  font-size: 1rem;
  font-weight: 600;
}

.toolbar-content {
  grid-template-columns: max-content 1fr max-content;
  grid-auto-flow: column;
  display: grid;
}

.tabs .is-active {
  color: #fff;
  background-color: #313a46;
}

.modal-wrap {
  max-height: 90vh;
  background-color: #efefef;
  border-radius: 10px;
  padding: 4rem 2.5rem 2rem;
  overflow: auto;
}

.tabulator-cell, .tabulator-responsive-collapse td, .tabulator-col-title {
  font-size: 11px;
}

body:not(.has-sidebar) .tabulator-cell, .tabulator-responsive-collapse td, .tabulator-col-title {
  font-size: 15px !important;
}

.modal-wrap.edit-user {
  display: block;
  height: 400px !important;
  max-height: 600px !important;
}

.vscomp-ele {
  min-width: 100%;
}

.vscomp-value {
  height: -webkit-max-content;
  height: max-content;
  width: 100% !important;
}

/*# sourceMappingURL=index.d28745d4.css.map */
